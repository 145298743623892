<template>
    <ErrorPageDefault class="error-page-404">
        <template #heading>
            <slot name="heading">
                {{ $t('Oops... this page no longer exists') }}
            </slot>
        </template>
        <template #text>
            <slot name="text">
                {{ $t('It appears to have been moved or removed.') }}
                <br />
                {{
                    $t(
                        // eslint-disable-next-line max-len
                        'Check out our proposals below and maybe you will find what you are looking for!'
                    )
                }}
            </slot>
        </template>
        <template #additional-content>
            <div class="buttons">
                <ButtonLarge
                    :variant="BUTTON_LARGE_SECONDARY"
                    :to="$tPath('new')"
                >
                    {{ $t('New') }}
                </ButtonLarge>
                <ButtonLarge
                    :variant="BUTTON_LARGE_SECONDARY"
                    :to="$tPath('sale')"
                >
                    {{ $t('Sale') }}
                </ButtonLarge>
                <ButtonLarge :variant="BUTTON_LARGE_SECONDARY" :to="HOME_PAGE">
                    {{ $t('Home page') }}
                </ButtonLarge>
            </div>
            <RecommendedProductsSliderWrapperNew
                v-if="!isSearchPage"
                :recommendation-type="WEB_EOB_ERROR_PAGE_1"
                :placement-page-name="ERROR_PAGE_PLACEMENT_PAGE_NAME"
                class="products-slider-container"
            />
        </template>
    </ErrorPageDefault>
</template>

<script>
import { SEARCH_RESULTS_PAGE_NAME } from '@search/routing/names';

import { ERROR_PAGE_PLACEMENT_PAGE_NAME } from '@configs/synerise';

import { WEB_EOB_ERROR_PAGE_1 } from '@types/Synerise';

import { HOME_PAGE } from '@router/paths';

import RecommendedProductsSliderWrapperNew from '@organisms/RecommendedProductsSliderWrapperNew/RecommendedProductsSliderWrapperNew';
import ErrorPageDefault from '@organisms/ErrorPage/ErrorPageDefault';

import {
    ButtonLarge,
    BUTTON_LARGE_VARIANTS,
} from '@eobuwie-ui/components/ButtonLarge/v1';

export default {
    name: 'ErrorPage404',

    components: {
        RecommendedProductsSliderWrapperNew,
        ButtonLarge,
        ErrorPageDefault,
    },

    computed: {
        isSearchPage() {
            return this.$route.name === SEARCH_RESULTS_PAGE_NAME;
        },
    },

    beforeCreate() {
        this.WEB_EOB_ERROR_PAGE_1 = WEB_EOB_ERROR_PAGE_1;
        this.HOME_PAGE = HOME_PAGE;
        this.BUTTON_LARGE_SECONDARY = BUTTON_LARGE_VARIANTS.SECONDARY;
        this.ERROR_PAGE_PLACEMENT_PAGE_NAME = ERROR_PAGE_PLACEMENT_PAGE_NAME;
    },
};
</script>

<style lang="scss" scoped>
.error-page-404 {
    .buttons {
        @apply pt-4 flex flex-col gap-3;
        min-width: theme('customVariables.errorPage.buttonMinWidth');
    }

    .products-slider-container {
        @apply mt-5;
    }

    @screen lg {
        .buttons {
            @apply flex-row;
        }

        .products-slider-container {
            @apply mt-7;
        }
    }
}
</style>
