<template>
    <ContainerContent class="error-page-default">
        <BaseHeading tag="h1" theme="h3" class="error-heading">
            <slot name="heading"> {{ $t('Sorry, an error occurred') }} </slot>
        </BaseHeading>
        <BaseParagraph class="error-paragraph">
            <slot name="text">
                {{
                    $t(
                        // eslint-disable-next-line max-len
                        'Our experts are already working on solving this problem! Please refresh the page or come back in a few minutes.'
                    )
                }}
            </slot>
        </BaseParagraph>
        <slot name="additional-content" />
        <ChatWrapper />
    </ContainerContent>
</template>

<script>
import BaseHeading from '@atoms/BaseHeading/BaseHeading';
import BaseParagraph from '@atoms/BaseParagraph/BaseParagraph';

import ChatWrapper from '@molecules/ChatWrapper/ChatWrapper';
import ContainerContent from '@molecules/ContainerContent/ContainerContent';

export default {
    name: 'ErrorPageDefault',
    components: {
        ContainerContent,
        BaseHeading,
        BaseParagraph,
        ChatWrapper,
    },
};
</script>

<style lang="scss" scoped>
.error-page-default {
    @apply text-center my-9 flex flex-col justify-center items-center;

    .error-paragraph {
        @apply pt-3 text-center;
    }

    @screen lg {
        .error-heading {
            @apply text-xxl leading-xxl;
        }
        .error-paragraph {
            @apply text-r leading-r;
        }
    }
}
</style>
