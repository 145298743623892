<template>
    <ErrorPage404 />
</template>

<script>
import { UNSUBSCRIBE_CODE_QUERY_PARAM_NAME } from '@configs/newsletter';
import { URL_KEY_ATTRIBUTE_NAME } from '@configs/product-attribute-names';

import { ERROR_ACTION_TAG_NAME } from '@types/Errors';

import { CORE_ERROR_DOMAIN } from '@errors/feature-domain-names';

import {
    NEWSLETTER_PAGE,
    NEWSLETTER_UNSUBSCRIBE_BY_CODE_PAGE_URL_KEY,
    INVITE_FRIEND_INACTIVE_REF_LINK_PAGE,
} from '@router/paths';

import { ASYNC_SEOMATIC_SERVICE_CONFIG } from '@async-services/seomatic/meta';

import { stringifyCategoryUrl } from '@assets/path';
import { addDisableCacheHeader } from '@assets/headers';
import { getUrlForSeomatic, makeSeomaticRedirect } from 'assets/seomatic';

import BasePage from '@pages/mixins/BasePage';

import Redirector from '@modules/m1-migration/redirector/Redirector';
import {
    getHost,
    makeRedirect,
    makeAbsoluteRedirect,
    includesWWWSubdomain,
} from '@modules/m1-migration/redirector/utils';

import ErrorPage404 from '@organisms/ErrorPage/ErrorPage404';

export default {
    name: 'NotFound',

    components: {
        ErrorPage404,
    },

    mixins: [BasePage({ sendPageEvent: false })],

    async asyncData({
        app,
        store,
        route: { path: currentUrl },
        route,
        redirect,
        req,
        res,
    }) {
        addDisableCacheHeader(res);

        const defaultRedirect = () => {
            if (includesWWWSubdomain(getHost(req))) {
                return makeRedirect(req, redirect, route.fullPath);
            }
        };

        const QUERY_STRING_SIGN = '?';
        let queryString = '';

        if (process.server) {
            queryString = req.url.split(QUERY_STRING_SIGN)[1] || '';
            res.statusCode = 404;
        } else {
            queryString = window.location.search.replace(QUERY_STRING_SIGN, '');
        }

        const queryStringSign = queryString ? QUERY_STRING_SIGN : '';

        const {
            config: {
                storeView: { base_currency_code: currency, locale },
            },
        } = store.state;

        const urlForSeomatic = getUrlForSeomatic(req, route);

        const seomatic = await app.$asyncServices.use(
            ASYNC_SEOMATIC_SERVICE_CONFIG.NAME,
            ASYNC_SEOMATIC_SERVICE_CONFIG.METHODS.GET_SEO,
            {
                url: urlForSeomatic,
            }
        );

        if (seomatic?.redirect) {
            try {
                return makeSeomaticRedirect(seomatic.redirect, redirect, route);
            } catch (err) {
                app.$errorHandler.captureDomainError(
                    CORE_ERROR_DOMAIN,
                    err,
                    {
                        [ERROR_ACTION_TAG_NAME]: 'makeSeomaticRedirect',
                    },
                    {
                        urlForSeomatic,
                        seomatic,
                    }
                );
            }
        }

        const isPl = locale === 'pl_PL';

        if (isPl) {
            const { to = '' } = await app.$services.category.checkRedirectForPL(
                {
                    slug: decodeURI(currentUrl),
                    locale,
                }
            );

            if (to) {
                return makeRedirect(req, redirect, to);
            }

            const isM1InviteFriendUrl = Redirector.isM1InviteFriendUrl(
                queryString
            );

            if (isM1InviteFriendUrl) {
                return makeRedirect(
                    req,
                    redirect,
                    `/${INVITE_FRIEND_INACTIVE_REF_LINK_PAGE}`
                );
            }

            if (decodeURI(currentUrl) === '/praca') {
                return makeAbsoluteRedirect(
                    req,
                    redirect,
                    `https://praca.modivo.pl${queryStringSign}${queryString}`
                );
            }
        }

        if (!Redirector.shouldCheckRedirects(locale) && !isPl) {
            return defaultRedirect();
        }

        const isM1UnsubscribeByTokenUrl = Redirector.isM1UnsubscribeByTokenNewsletterPage(
            currentUrl
        );

        if (isM1UnsubscribeByTokenUrl) {
            const TOKEN_QUERY_PARAM_NAME = 'token';

            const replacedQueryString = queryString.replace(
                TOKEN_QUERY_PARAM_NAME,
                UNSUBSCRIBE_CODE_QUERY_PARAM_NAME
            );

            return makeRedirect(
                req,
                redirect,
                // eslint-disable-next-line max-len
                `/${NEWSLETTER_PAGE}/${NEWSLETTER_UNSUBSCRIBE_BY_CODE_PAGE_URL_KEY}${queryStringSign}${replacedQueryString}`
            );
        }

        const staticRedirects = Redirector.getRedirectsForLocale(locale);
        const staticRedirect = staticRedirects?.[decodeURI(currentUrl)];

        if (staticRedirect && !isPl) {
            return makeRedirect(
                req,
                redirect,
                `${staticRedirect}${queryStringSign}${queryString}`
            );
        }

        const isM1CategoryUrl = Redirector.isM1CategoryPage(currentUrl, locale);
        const isM1CategoryWithOneFilterUrl = Redirector.isM1CategoryPageWithOneFilter(
            currentUrl,
            locale
        );

        if (isM1CategoryUrl) {
            const categorySlug = Redirector.getCategorySlugFromUrl(currentUrl);

            try {
                const pageData = await app.$services.category.checkRedirect({
                    categories: [categorySlug],
                    locale,
                    currency,
                });

                if (!pageData) {
                    return defaultRedirect();
                }

                const categoryUrl =
                    pageData?.navigation?.categories?.[0]?.translations?.[
                        locale
                    ]?.url || '';

                if (categoryUrl) {
                    const categoryFiltersFromUrl = Redirector.getCategoryFiltersFromUrl(
                        currentUrl,
                        categoryUrl,
                        locale
                    );

                    const filters = isM1CategoryWithOneFilterUrl
                        ? categoryFiltersFromUrl
                        : {};

                    const categoryPath = app.$createCategoryPath(
                        stringifyCategoryUrl({
                            categoryUrl,
                            filters,
                        })
                    );

                    return makeRedirect(
                        req,
                        redirect,
                        `${categoryPath}${queryStringSign}${queryString}`
                    );
                }
            } catch (err) {
                app.$errorHandler.captureError(err, {
                    [ERROR_ACTION_TAG_NAME]: 'migration.category',
                });
            }
        }

        const isM1ProductUrl = Redirector.isM1ProductPage(currentUrl, locale);

        if (isM1ProductUrl) {
            const productSlug = Redirector.getProductSlugFromUrl(currentUrl);

            try {
                const product = await app.$services.product.checkRedirect({
                    urlKey: productSlug,
                    locale,
                    currency,
                });

                if (!product) {
                    return defaultRedirect();
                }

                const urlKey =
                    product?.values?.[URL_KEY_ATTRIBUTE_NAME]?.value?.[locale];

                if (urlKey) {
                    const productPath = app.$createProductPath(urlKey);

                    return makeRedirect(
                        req,
                        redirect,
                        `${productPath}${queryStringSign}${queryString}`
                    );
                }
            } catch (err) {
                app.$errorHandler.captureError(err, {
                    [ERROR_ACTION_TAG_NAME]: 'migration.product',
                });
            }
        }

        return defaultRedirect();
    },

    mounted() {
        this.isCustomerFetchedDeferred.promise.then(() => {
            this.sendAnalyticsEvents();
        });
    },

    methods: {
        sendAnalyticsEvents() {
            this.emitPageEvent();
        },
    },
};
</script>
